import React from 'react';
import * as styles from './hero-carousel.module.scss';

import { Carousel } from 'react-responsive-carousel';
import { GatsbyImage } from 'gatsby-plugin-image';

const HeroCarousel = ({ images }) => (
  <section className={styles.carouselWrapper}>
    <Carousel
      infiniteLoop
      autoPlay
      swipeable
      interval={5000}
      transitionTime={1000}
      showStatus={false}
      showThumbs={false}
      showArrows
    >
      {images.map(({ node }) => (
        <div key={node.id}>
          <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base} className={styles.carouselImage} />
        </div>
      ))}
    </Carousel>
  </section>
);

export default HeroCarousel;
