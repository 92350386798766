import 'swiper/css';
import 'swiper/css/pagination';

import * as styles from './testimonial-swiper.module.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

import React from 'react';

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

const TestimonialSwiper = ({ testimonials, slidesPerGroup = 1, spaceBetween = 30, delay = 8000 }) => {
  return (
    <section className={styles.testimonials}>
      <Swiper
        spaceBetween={spaceBetween}
        slidesPerGroup={slidesPerGroup}
        centeredSlides={true}
        autoplay={{
          delay: delay,
          disableOnInteraction: false,
        }}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
      >
        {testimonials.map(({ content, fullname }, index) => (
          <SwiperSlide key={index}>
            <div className={styles.swiperSlideContent}>
              <p>{content}</p>
              <span>{fullname}</span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default TestimonialSwiper;
