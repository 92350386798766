import * as styles from './school-info-section.module.scss';

import React from 'react';

import Calendar from '-!svg-react-loader!../../assets/calendar.svg';
import Certificate from '-!svg-react-loader!../../assets/certificate.svg';
import GraphIncrease from '-!svg-react-loader!../../assets/graph-increase.svg';
import Smiley from '-!svg-react-loader!../../assets/smiley.svg';
import Location from '-!svg-react-loader!../../assets/location-white.svg';
import Lock from '-!svg-react-loader!../../assets/lock.svg';
import AnimateIn from '../AnimateIn';

const SchoolInfoSection = () => {
  const iconsWithDescription = [
    {
      icon: <GraphIncrease className={styles.icon} />,
      description: 'Fondata nel 2014',
    },
    {
      icon: <Smiley className={styles.icon} />,
      description: '500+ allieve soddisfatte',
    },
    {
      icon: <Lock className={styles.icon} />,
      description: '100% risultato garantito',
    },
    {
      icon: <Location className={styles.icon} />,
      description: '6 palestre fra osio e stezzano',
    },
    {
      icon: <Calendar className={styles.icon} />,
      description: 'Orari flessibili',
    },
    {
      icon: <Certificate className={styles.icon} />,
      description: 'Insegnanti certificati',
    },
  ];

  return (
    <section className={styles.whyUsSection}>
      <h1>LA NOSTRA SCUOLA</h1>

      <div className={styles.whyUsContentWrapper}>
        {iconsWithDescription.map(({ icon, description }, index) => (
          <AnimateIn slideUp key={index}>
            <div className={styles.iconWrapper}>
              {icon}
              <p className={styles.iconDescription}>{description}</p>
            </div>
          </AnimateIn>
        ))}
      </div>
    </section>
  );
};

export default SchoolInfoSection;
