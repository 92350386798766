import 'react-responsive-carousel/lib/styles/carousel.min.css';

import * as styles from '../styles/home.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';

import HeroCarousel from '../components/HeroCarousel';
import Layout from '../components/Layout';
import React from 'react';
import SchoolInfoSection from '../components/SchoolInfoSection';
import ScrollToTopButton from '../components/ScrollToTopButton/ScrollToTopButton';
import Seo from '../components/SEO';
import TestimonialSwiper from '../components/TestimonialSwiper';
import AnimateIn from '../components/AnimateIn';

const Home = ({ data }) => {
  const cards = data.homeCards.nodes;
  const { testimonials } = data.testimonials.nodes[0].frontmatter;

  const carousel = <HeroCarousel images={data.slideShow.edges} />;

  const whyUsSection = <SchoolInfoSection />;

  const isTestimonialEnabled = false;

  return (
    <>
      <Layout carousel={carousel} whyUsSection={whyUsSection}>
        <Seo title={'PILART'} description={'Scuola di pilates e danza a Stezzano e Osio Sotto'} />

        <AnimateIn transition={500} slideUp>
          <section className={styles.sectionCardBody}>
            {cards.map(({ frontmatter, id }) => {
              const featuredImage = getImage(frontmatter?.featuredImg?.childImageSharp?.gatsbyImageData);

              return (
                <Link to={frontmatter.link} className={styles.card} key={id}>
                  <GatsbyImage image={featuredImage} alt={frontmatter.alt} className={styles.cardImage} />
                  <div className={styles.cardContentWrapper}>
                    <h4>
                      <b>{frontmatter.title}</b>
                    </h4>
                    <span className={styles.cardContent}> {frontmatter.content} </span>
                  </div>
                </Link>
              );
            })}
          </section>
        </AnimateIn>

        {isTestimonialEnabled && (
          <section className={styles.testimonialSection}>
            <h2>TESTIMONIALS</h2>
            <TestimonialSwiper testimonials={testimonials} />
          </section>
        )}
        <ScrollToTopButton />
      </Layout>
    </>
  );
};

export const HomePageQuery = graphql`
  query HomePageQuery {
    slideShow: allFile(filter: { relativeDirectory: { eq: "carousel" } }, sort: { order: ASC, fields: name }) {
      edges {
        node {
          id
          base
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, jpgOptions: { quality: 100 }, quality: 100)
          }
        }
      }
    }

    testimonials: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { type: { eq: "testimonials" } } }
    ) {
      nodes {
        frontmatter {
          testimonials {
            fullname
            content
          }
        }
      }
    }

    homeCards: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { type: { eq: "home-page-card" } } }
    ) {
      nodes {
        frontmatter {
          type
          title
          alt
          link
          content
          featuredImg {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO)
            }
          }
        }
        id
      }
    }
  }
`;

export default Home;
